<template>
  <div>
      <!-- <SideMenu /> -->
      <div v-if="userType == 1 || permissionIsAssigned('payroll-read')"
          class="main-content-wrap sidenav-open d-flex flex-column">
          <div class="main-header pl-4" style="z-index: 60;">
              <div class="row">
                  <div style="align-self: center;" class="logo col-md-2 col-lg-2 col-12 py-0">
                      <div class="dropdown animate__animated animate__fadeIn" style="font-size: 120%;">
                          <i @click="
                              payrunView == 3
                                  ? (payrunView = 1)
                                  : hasHistory()
                                      ? $router.go(-1)
                                      : $router.push('/')
                              " style="cursor: pointer; position: relative; top: 2px; color: #069;"
                              class="fal fa-arrow-alt-circle-left mr-1"></i>
                          <span class="ml-2" style="top: 4%; position: relative;">Back</span>
                      </div>
                  </div>
                  <!-- FOR OTHER ROLES ASSIGNED THE PERMISSION OF READING PAYROLL -->
              </div>
              <div style="margin: auto;"></div>
              <div class="header-part-right" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                  ? null
                  : 'pr-5'
                  ">
                  <!-- Notificaiton -->
                  <div class="dropdown animate__animated animate__fadeIn">
                      <button v-if="payrunView != 2" @click="viewAllPayruns()" style="float: right;" type="button"
                          class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                          View Pay Runs
                      </button>
                      <button v-if="payrunView !== 1 && payrunView !== 3" @click="viewDefault()"
                          style="float: right;" type="button"
                          class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                          Create Pay Run
                      </button>
                      <!-- <button v-if="payrunView !== 6" @click="viewCompanyTrasactions()" style="float: right;" type="button"
                          class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                          View Company Transactions
                      </button>
                      <div v-else>
                          <button @click="payrunView = 1" style="float: right;" type="button"
                              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                              Create Pay Run
                          </button>
                      </div>
                      -->
                  </div>
                  <!-- Notificaiton End -->
                  <!-- User avatar dropdown -->
              </div>
          </div>
          <!-- ======== Body content start ========= -->
          <div v-if="payrunView == 1" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? 'px-3'
              : null
              ">
              <div class="breadcrumb" style="margin-top: 1rem;">
                  <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                      ">
                      <h1 class="col-12 my-0 row pb-0">
                          <span class="col-lg-12 col-md-12 col-12">Payrun</span>
                      </h1>
                      <div :class="$vuetify.breakpoint.name == 'xs' ||
                          $vuetify.breakpoint.name == 'sm'
                          ? 'px-0'
                          : 'px-7'
                          " class="col-12 row pt-0 mt-0">
                          <div v-for="(pc, index) in authPayCalendars" :key="'pay-calendar-' + pc.id"
                              class="col-lg-4 col-md-6 col-sm-6">
                              <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                                  :style="getStyleByPattern(index)">
                                  <div class="card-header">
                                      {{ pc.calendar_name }}
                                  </div>
                                  <div class="row card-body text-center py-1 mt-0">
                                      <p class="col-12 text-left py-1 mb-1"><strong>Calendar Name: </strong>{{
                                          pc.calendar_name }}</p>
                                      <p class="col-12 text-left py-1 mb-1"><strong>Calendar Type: </strong>{{
                                          pc.calendar_type }}</p>
                                      <p class="col-12 text-left py-1 mb-1"><strong>No. of Employees: </strong>{{
                                          pc.pay_calendar_employees_count }}</p>
                                  </div>
                                  <div v-if="permissionIsAssigned('payroll-create') && pc.pay_calendar_employees_count > 0"
                                      class="card-footer py-1 text-right row">
                                      <div class="col-lg-5 row py-0"></div>
                                      <div class="col-lg-6 offset-lg-1 py-0 pb-3 px-0">
                                          <button @click="startPayrun(pc.id)" type="button"
                                              class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                                              Start Payrun
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <!-- ICON BG-->
              </div>
          </div>
            <div v-else-if="payrunView == 2" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-lg-12 col-md-12 col-12">Payrun</span>
                        </h1>
                        <div class="col-12" :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'pr-7'
                            ">
                            <div class="card" style="border-radius: 10px;">
                                <div class="card-body px-1 py-1 pt-0 pr-0">
                                    <div class="table-responsive"
                                        style="border-radius: 10px; height: 70vh; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                        <download-excel style="position: fixed; top: 0px;" id="export-button"
                                            :data="jsonData" :fields="jsonFields" :worksheet="excelSheetName"
                                            :name="excelSheetName + '.xls'"
                                            class="btn btn-raised ripple btn-raised-secondary btn-sm m-1 text-white" :class="$vuetify.breakpoint.name == 'xs' ||
                                                $vuetify.breakpoint.name == 'sm'
                                                ? 'w-100'
                                                : null
                                                ">
                                            Export to Excel
                                        </download-excel>
                                        <table class="table table-hover">
                                            <thead>
                                                <tr style="border: 2px solid #4440;">
                                                    <th scope="col" class="text-left">Date Created</th>
                                                    <th scope="col" class="text-left">Name</th>
                                                    <th scope="col" class="text-left">From</th>
                                                    <th scope="col" class="text-left">To</th>
                                                    <th scope="col" class="text-left">Payment Date</th>
                                                    <th scope="col" class="text-center">Status</th>
                                                    <!-- <th v-if="permissionIsAssigned('payroll-approve')" scope="col"
                                                        class="text-center">
                                                        Salaries Payment
                                                    </th> -->
                                                    <th v-if="permissionIsAssigned('payroll-read') || permissionIsAssigned('payroll-update') ||
                                                        permissionIsAssigned('payroll-delete')
                                                        " scope="col" class="text-left">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(payRun, payRunIndex) in authCompanyPayRun" :key="`pay-run-${payRunIndex}`">
                                                    <td class="text-left">
                                                        {{ new Date(payRun.created_at).toUTCString().substring(0, 16) }}
                                                    </td>
                                                    <td class="text-left">{{ payRun.payrun_name }}</td>
                                                    <td class="text-left">
                                                        {{ payRun.from_date }}
                                                    </td>
                                                    <td class="text-left">
                                                        {{ payRun.to_date }}
                                                    </td>
                                                    <td class="text-left">
                                                        {{ payRun.payment_date }}
                                                    </td>
                                                    <td v-if="payRun.approved == 0">
                                                        <span class="badge badge-light text-center">UNAPPROVED</span>
                                                    </td>
                                                    <td v-else>
                                                        <span class="badge badge-success text-center">APPROVED</span>
                                                    </td>
                                                    <!-- <td v-if="permissionIsAssigned('payroll-approve')">
                                                        <i v-if="payRun.approved == 0">Payrun unapproved</i>
                                                        <button v-else @click="launchSalaryPayment(payRun)" type="button"
                                                            class="btn btn-sm btn-secondary text-white btn-raised ripple"
                                                            :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null">
                                                            Process Payment
                                                        </button>
                                                    </td> -->
                                                    <td v-if="permissionIsAssigned('payroll-read') || permissionIsAssigned('payroll-update') ||
                                                        permissionIsAssigned('payroll-delete')
                                                        " style="padding-top: 25px;"
                                                        class="text-left employee-options">
                                                        <div class="row">
                                                            <i v-if="permissionIsAssigned('payroll-read')" @click="exportToExcel(payRun)"
                                                                class="col-2 py-0 text-center fal fa-file-spreadsheet"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            <i v-if="permissionIsAssigned('payroll-read')" @click="triggerPrintPayRunBreakdown(payRun)"
                                                                class="col-2 py-0 text-center fal fa-print"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            <i v-if="permissionIsAssigned('payroll-update') &&
                                                                payRun.approved == 0
                                                                " @click="editPayRun(payRun)"
                                                                class="col-2 py-0 text-center fal fa-edit"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            <i v-if="permissionIsAssigned('payroll-approve') &&
                                                                payRun.approved != 0
                                                                " @click="viewPayrunInfo(payRun)"
                                                                class="col-2 py-0 text-center fal fa-eye"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                            <i v-if="permissionIsAssigned('payroll-delete')" @click="deletePayRun(payRun)"
                                                                class="col-2 py-0 text-center fal fa-trash"
                                                                style="color: #069 !important; font-size: 125%; cursor: pointer;"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
                <PayRunBreakdown 
                v-if="approvedPayrollBreakdownData"
                class="d-none d-print"
                :authCompanyData="authCompanyData"
                :host_url="host_url"
                :approvedPayrollBreakdownData="approvedPayrollBreakdownData"
                :showSignature="false"
                />
            </div>
            <div v-else-if="payrunView == 3" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-6">Payrun</span>
                            <span class="col-6 text-right">
                                <span v-if="currentPayRun ? currentPayRun.approved == 1 : null"
                                    class="badge badge-success">APPROVED</span>
                                <span v-else class="badge badge-light">UNAPPROVED</span>
                            </span>
                        </h1>
                        <div class="col-12" :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'px-7'
                            ">
                            <v-stepper v-model="steps" non-linear>
                                <v-stepper-header>
                                    <v-stepper-step :editable="false" step="1">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ?
                                            "Employees" : null }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="false" step="2">
                                        {{
                                            $vuetify.breakpoint.name != "xs"
                                            &&
                                            $vuetify.breakpoint.name != "sm"
                                            ?
                                            "Variable Input"
                                            : null
                                        }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="false" step="3">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "PaySlips"
                                            : null }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="false" step="4">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "Approval"
                                            : null }}
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="1">
                                        <div class="container-fluid">
                                            <v-form ref='form1'>
                                                <v-row>
                                                    <v-col cols="12" sm="3" md="3" class="pb-0">
                                                        <span style="color: #111d5e;">From Date <i class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field color="#069"
                                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                                    v-model="payrunForm.fromDate" placeholder="From date" solo
                                                                    class="mt-1" dense readonly v-bind="attrs"
                                                                    v-on="on" :rules="[
                                        v => !!v || 'Date is required'
                                    ]"></v-text-field>
                                                            </template>
                                                            <v-date-picker color="#069" :active-picker.sync="picker"
                                                                v-model="payrunForm.fromDate" @change="save" :min="new Date(new Date().getTime() - 1.581e10)
                                                                    .toISOString()
                                                                    .substring(0, 10)
                                                                    " :max="new Date(new Date().getTime() + 2.678e9)
            .toISOString()
            .substring(0, 10)
            "></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="3" md="3" class="pb-0">
                                                        <span style="color: #111d5e;">To Date <i class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                        <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field color="#069"
                                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                                    v-model="payrunForm.toDate" placeholder="To date" solo
                                                                    class="mt-1" dense readonly v-bind="attrs" v-on="on" :rules="[
                                        v => !!v || 'Date is required'
                                    ]">
                                                                </v-text-field>
                                                            </template>
                                                            <v-date-picker color="#069" :active-picker.sync="picker2"
                                                                v-model="payrunForm.toDate" @change="save2" :min="new Date(new Date().getTime() - 1.581e10)
                                                                    .toISOString()
                                                                    .substring(0, 10)
                                                                    " :max="new Date(new Date().getTime() + 2.678e9)
            .toISOString()
            .substring(0, 10)
            "></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" sm="3" md="3" class="pb-0">
                                                        <span style="color: #111d5e;">Payment Date <i class="fas fa-asterisk"
                                                                style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                        <v-menu ref="menu3" v-model="menu3" :close-on-content-click="false"
                                                            transition="scale-transition" offset-y min-width="auto">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field color="#069"
                                                                    prepend-inner-icon="mdi-calendar-month-outline"
                                                                    v-model="payrunForm.paymentDate" placeholder="Payment date"
                                                                    solo class="mt-1" dense readonly v-bind="attrs"
                                                                    v-on="on" :rules="[
                                        v => !!v || 'Date is required'
                                    ]"></v-text-field>
                                                            </template>
                                                            <v-date-picker color="#069" :active-picker.sync="picker3"
                                                                v-model="payrunForm.paymentDate" @change="save3" :min="new Date(new Date().getTime() - 1.581e10)
                                                                    .toISOString()
                                                                    .substring(0, 10)
                                                                    " :max="new Date(new Date().getTime() + 2.678e9)
            .toISOString()
            .substring(0, 10)
            "></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col cols="12" class="card my-2" style="border-radius: 10px;">
                                                        <div class="card-header px-3">
                                                            <h4>Active Employees</h4>
                                                        </div>
                                                        <div class="card-body px-1 py-1 pt-0 pr-0">
                                                            <div class="table-responsive" :style="$vuetify.breakpoint.name == 'xs' ||
                                                                $vuetify.breakpoint.name == 'sm'
                                                                ? 'height: 30vh;'
                                                                : 'height: 30vh;'
                                                                "
                                                                style="border-radius: 10px; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Employee
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Department
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Basic Salary
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Allowances
                                                                            </th>
                                                                            <th v-if="basicPayrollData.isTaxCompliant" class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Statutory Deductions
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                <span v-if="basicPayrollData.isTaxCompliant">Non-Statutory Deductions</span>
                                                                                <span v-else>Deductions</span>
                                                                            </th>
                                                                            <th class="text-left" style="border-top: none;"
                                                                                scope="col">
                                                                                Net Pay
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(employeePayData, employeePayDataIndex) in basicPayrollData.paginator.data" :key="'employee-in-pay-calendar-' + employeePayDataIndex">
                                                                            <!-- Employee -->
                                                                            <td class="text-left">
                                                                                {{ employeePayData.employeeName }}
                                                                            </td>
                                                                            <!-- Department -->
                                                                            <td class="text-left">
                                                                                {{ employeePayData.department }}
                                                                            </td>
                                                                            <!-- Basic Salary -->
                                                                            <td class="text-left">
                                                                                ₦{{ employeePayData.salaryAmount | number_with_commas }}
                                                                            </td>
                                                                            <!-- Allowances -->
                                                                            <td class="text-left">
                                                                                ₦{{ employeePayData.allowancesAmount | number_with_commas }}
                                                                            </td>

                                                                            <!-- Statutory Deductions -->
                                                                            <td v-if="basicPayrollData.isTaxCompliant" class="text-left">
                                                                              ₦{{ employeePayData.employeeStatutoryDeductionsAmount | number_with_commas }}
                                                                            </td>

                                                                            <!-- Non-Statutory Deductions -->
                                                                            <td class="text-left">
                                                                              ₦{{ employeePayData.employeeNonStatutoryDeductionsAmount | number_with_commas }}
                                                                            </td>

                                                                            <!-- Net Pay -->
                                                                            <td class="text-left">
                                                                              ₦{{ employeePayData.netPayAmount | number_with_commas }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer p-0 pt-3 bg-white">
                                                            <ul class="pagination">
                                                                <li 
                                                                v-for="(link, linkIndex) in basicPayrollData.paginator.links"
                                                                :key="`pagination-link-${linkIndex}`"
                                                                class="paginate_button page-item px-0 " 
                                                                :class="(link.url ? '' : 'disabled ') + (link.active ? 'active' : '')"
                                                                >
                                                                  <a 
                                                                  href="#" 
                                                                  class="page-link"
                                                                  v-html="link.label"
                                                                  @click.prevent="updatePayrunBasicData(link.url)"
                                                                  ></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="12" sm="3" lg="1" md="3" xl="1" offset-lg="11"
                                                                offset-md="9" offset-xl="11" class="pt-0">
                                                                <button @click="addPayrun()"
                                                                    style="width: 100%;" type="button"
                                                                    class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                    Next
                                                                </button>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </div>
                                    </v-stepper-content>
                                    
                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="2">
                                        <div class="container-fluid pb-4">
                                            <v-row>
                                                <v-col cols="12" lg="5" xl="5" class="m0-3" style="border-radius: 10px;">
                                                    <div class="card">
                                                        <div class="card-header px-3">
                                                            <h4>Employees profile information</h4>
                                                        </div>
                                                        <div v-if="employeeBasicPayrollData" class="card-body px-3 py-5">
                                                            <v-row>
                                                                <v-col cols="12" class="pb-0">
                                                                    <v-select class="remove-mb"
                                                                        :items="payCalendarEmployees"
                                                                        item-text="employeeName" return-object
                                                                        placeholder="Employee" dense solo
                                                                        hint="*Select Employee" :full-width="true"
                                                                        v-model="selectedEmployeePayCalendar"></v-select>
                                                                </v-col>
                                                                <v-col cols="12" class="pt-2">
                                                                    <v-row>
                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                Emoluments
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                        </v-col>

                                                                        <v-col cols="12"
                                                                            v-for="(emolument, emolumentIndex) in employeeBasicPayrollData.employeeEmoluments"
                                                                            :key="`emolument-${emolumentIndex}`">
                                                                            <v-row>
                                                                              <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                                  <h5>
                                                                                      {{ emolument.name }}
                                                                                  </h5>
                                                                                  <span>
                                                                                      {{ emolument.note }}
                                                                                  </span>
                                                                              </v-col>
                                                                              <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                                  <h5>
                                                                                      ₦{{ emolument.amount | number_with_commas }}
                                                                                  </h5>
                                                                              </v-col>
                                                                            </v-row>
                                                                        </v-col>

                                                                        <v-col v-if="employeeBasicPayrollData.allowancesAmount > 0" cols="8" lg="9" xl="9" md="9"
                                                                            class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                Allowances
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="employeeBasicPayrollData.allowancesAmount > 0" cols="4" lg="3" xl="3" md="3"
                                                                            class="pb-0">
                                                                        </v-col>

                                                                        <v-col cols="12" v-for="(allowance, allowanceIndex) in employeeBasicPayrollData.allowances" :key="`allowance-${allowanceIndex}`">
                                                                            <v-row>
                                                                              <v-col cols="8" lg="9" xl="9" md="9"
                                                                                  class="pb-0">
                                                                                  <h5>
                                                                                      <i class="fas fa-times-circle"
                                                                                          style="cursor: pointer;" @click="
                                                                                              deleteFlexibleAllowance(allowance)
                                                                                              "></i>
                                                                                      {{ allowance.name }}
                                                                                  </h5>
                                                                                  <span v-if="allowance.note">{{ allowance.note }}</span>
                                                                              </v-col>
                                                                              <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                                  <h5>
                                                                                      ₦{{ allowance.amount | number_with_commas }}
                                                                                  </h5>
                                                                              </v-col>
                                                                            </v-row>
                                                                        </v-col>

                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Total Allowances
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ employeeBasicPayrollData.allowancesAmount | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Gross Pay
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ employeeBasicPayrollData.grossPayAmount | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col v-if="basicPayrollData.isTaxCompliant" cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                Statutory Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="basicPayrollData.isTaxCompliant" cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                        </v-col>

                                                                        <v-col cols="12"
                                                                            v-for="(deduction, deductionIndex) in employeeBasicPayrollData.employeeStatutoryDeductions" :key="`statutory-deduction-${deductionIndex}`">
                                                                            <v-row>
                                                                              <v-col cols="8" lg="9" xl="9" md="9"
                                                                                  class="pb-0">
                                                                                  <h5>
                                                                                      {{ deduction.name }}
                                                                                  </h5>
                                                                                  <span>{{ deduction.note }}</span>
                                                                              </v-col>
                                                                              <v-col cols="4" lg="3" xl="3" md="3"
                                                                                  class="pb-0">
                                                                                  <h5>
                                                                                      ₦{{ deduction.amount | number_with_commas }}
                                                                                  </h5>
                                                                              </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col v-if="basicPayrollData.isTaxCompliant" cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Total Statutory Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="basicPayrollData.isTaxCompliant" cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ employeeBasicPayrollData.employeeStatutoryDeductionsAmount | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col v-if="employeeBasicPayrollData.employeeNonStatutoryDeductionsAmount > 0" cols="8" lg="9" xl="9" md="9"
                                                                            class="pb-0">
                                                                            <h5 style="font-weight: bold;">
                                                                                {{ basicPayrollData.isTaxCompliant ? 'Non-Statutory ' : '' }} Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col v-if="employeeBasicPayrollData.employeeNonStatutoryDeductionsAmount > 0" cols="4" lg="3" xl="3" md="3"
                                                                            class="pb-0">
                                                                        </v-col>

                                                                        <v-col cols="12" v-for="(loanDeduction, loanDeductionIndex) in employeeBasicPayrollData.loanDeductions" :key="`loan-deduction-${loanDeductionIndex}`">
                                                                            <v-row>
                                                                              <v-col cols="8" lg="9" xl="9" md="9"
                                                                                  class="pb-0">
                                                                                  <h5>
                                                                                    <i class="fas fa-times-circle"
                                                                                      style="cursor: pointer;" @click="
                                                                                          deleteFlexibleDeduction(loanDeduction)
                                                                                          "></i>
                                                                                      {{ loanDeduction.name }}
                                                                                  </h5>
                                                                                  <span v-if="loanDeduction.note">{{ loanDeduction.note }}</span>
                                                                              </v-col>
                                                                              <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                                  <h5>
                                                                                      ₦{{ loanDeduction.amount | number_with_commas }}
                                                                                  </h5>
                                                                              </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="12" v-for="(deduction, deductionIndex) in employeeBasicPayrollData.deductions" :key="`deduction-${deductionIndex}`">
                                                                            <v-row>
                                                                              <v-col cols="8" lg="9" xl="9" md="9"
                                                                                  class="pb-0">
                                                                                  <h5>
                                                                                    <i class="fas fa-times-circle"
                                                                                      style="cursor: pointer;" @click="
                                                                                          deleteFlexibleDeduction(deduction)
                                                                                          "></i>
                                                                                      {{ deduction.name }}
                                                                                  </h5>
                                                                                  <span v-if="deduction.note">{{ deduction.note }}</span>
                                                                              </v-col>
                                                                              <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                                  <h5>
                                                                                      ₦{{ deduction.amount | number_with_commas }}
                                                                                  </h5>
                                                                              </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Total {{ basicPayrollData.isTaxCompliant ? 'Non-Statutory ' : '' }} Deductions
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ employeeBasicPayrollData.employeeNonStatutoryDeductionsAmount | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>

                                                                        <v-col cols="8" lg="9" xl="9" md="9" class="pb-0">
                                                                            <h5
                                                                                style="border-top: 1px solid #8c8c8c; font-weight: bold;">
                                                                                Net Pay
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="4" lg="3" xl="3" md="3" class="pb-0">
                                                                            <h5 style="border-top: 1px solid #8c8c8c;">
                                                                                ₦{{ employeeBasicPayrollData.netPayAmount | number_with_commas }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col v-if="employeeBasicPayrollData" cols="12" lg="7" xl="7" class="mb-3" style="border-radius: 10px;">
                                                    <div class="card pb-8">
                                                        <div class="card-header px-3">
                                                            <h4>
                                                                Additional allowance or deduction for this payrun only
                                                            </h4>
                                                        </div>
                                                        <div class="card-body px-3 py-5">
                                                            <v-row>
                                                                <v-col cols="12" class="py-0">
                                                                    <v-row>
                                                                        <v-col cols="12" class="py-1 mt-2">
                                                                            <h5 class="mb-0 d-inline-block"
                                                                                style="background: #e2e2e2;">
                                                                                Additional Pay
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Allowance Name <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-select class="remove-mb"
                                                                                :items="configAllowances" item-text="payment_item" item-value="payment_item" dense solo
                                                                                :full-width="true" v-model="flexibleAllowanceForm.allowanceName
                                                                                    ">
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col cols="12" md="3" lg="3" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Amount <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-text-field v-model="flexibleAllowanceForm.allowanceAmount
                                                                                " solo dense
                                                                                type="number"></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Note</span>
                                                                            <v-text-field
                                                                                v-model="flexibleAllowanceForm.note" solo
                                                                                dense></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="1" lg="1" class="pt-0 pb-2">
                                                                            <span style="opacity: 0;">A</span>
                                                                            <button @click="addFlexibleAllowance()" style="width: 100%;" type="button"
                                                                                class="btn btn-secondary px-0 btn-raised ripple">
                                                                                <v-icon>
                                                                                    fal fa-plus text-white
                                                                                </v-icon>
                                                                            </button>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="12" class="py-0">
                                                                    <v-row :class="$vuetify.breakpoint.name == 'xs' ||
                                                                        $vuetify.breakpoint.name == 'sm'
                                                                        ? 'mt-8'
                                                                        : null
                                                                        ">
                                                                        <v-col cols="12" class="py-1 mt-2">
                                                                            <h5 class="mb-0 d-inline-block"
                                                                                style="background: #e2e2e2;">
                                                                                Additional Deduction
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Deduction Name <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-select class="remove-mb"
                                                                                :items="configDeductions" item-text="payment_item" item-value="payment_item" dense solo
                                                                                :full-width="true" v-model="flexibleDeductionForm.deductionName
                                                                                    ">
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col cols="12" md="3" lg="3" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Amount <i
                                                                                    class="fas fa-asterisk"
                                                                                    style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                                                            <v-text-field v-model="flexibleDeductionForm.deductionAmount
                                                                                " solo dense
                                                                                type="number"></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="4" lg="4" class="pt-0 pb-2">
                                                                            <span style="color: #111d5e;">Note</span>
                                                                            <v-text-field
                                                                                v-model="flexibleDeductionForm.note" solo
                                                                                dense></v-text-field>
                                                                        </v-col>
                                                                        <v-col cols="12" md="1" lg="1" class="pt-0 pb-2">
                                                                            <span style="opacity: 0;">A</span>
                                                                            <button @click="addFlexibleDeduction()" style="width: 100%;" type="button"
                                                                                class="btn btn-secondary px-0 btn-raised ripple">
                                                                                <v-icon>
                                                                                    fal fa-plus text-white
                                                                                </v-icon>
                                                                            </button>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>

                                                    <v-row class="mt-5" :class="$vuetify.breakpoint.name == 'xs' ||
                                                        $vuetify.breakpoint.name == 'sm'
                                                        ? 'px-2'
                                                        : null
                                                        ">
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="8" offset-xl="8"
                                                            class="pt-0">
                                                            <button @click="goToFirstStep" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                        <v-col cols="6" lg="2" xl="2" class="pt-0">
                                                            <button @click="steps += 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Next
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="3">
                                        <div class="container-fluid">
                                            <v-row>
                                                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                                                    <div v-if="employeeBasicPayrollData" id="employeeBreakdown" class="card">
                                                        <div class="card-header px-3">
                                                            <h4>Employee Payslip Summary</h4>
                                                        </div>
                                                        <div class="card-body px-3 pt-1 pb-8">
                                                            <div class="noShowOnPrint d-print-none row">
                                                                <v-col cols="12" lg="4" md="4" class="pb-0">
                                                                    <v-select class="remove-mb"
                                                                        :items="payCalendarEmployees"
                                                                        item-text="employeeName" return-object
                                                                        placeholder="Employee" dense solo
                                                                        hint="*Select Employee" :full-width="true"
                                                                        v-model="selectedEmployeePayCalendar"></v-select>
                                                                </v-col>
                                                                <v-col cols="12" lg="8" md="8" class="pb-0 text-right">
                                                                    <button @click="printEmployeeBreakdown" type="button"
                                                                        class="btn btn-outline-secondary m-1">
                                                                        Print Payslip
                                                                    </button>
                                                                </v-col>
                                                            </div>
                                                            <v-row class="add-mt">
                                                                <v-col cols="6">
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <h3 class="text-left">
                                                                                {{ authCompanyData.company_name }}
                                                                            </h3>
                                                                            <p>
                                                                                <i>{{ authCompanyData.address }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Name
                                                                            </h5>
                                                                            <p>{{ selectedEmployeePayCalendar.employeeName }}</p>
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Address
                                                                            </h5>
                                                                            <p>{{ selectedEmployeePayCalendar.employeeAddress }}</p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="6" class="text-center">
                                                                    <img :src="authCompanyData.logo
                                                                            ? host_url + authCompanyData.logo
                                                                            : '/img/kylian-logo-22.png'
                                                                        " alt="" class="w-50"
                                                                        style="height: inherit;" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mx-1">
                                                                <v-col cols="12">
                                                                    <v-row>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Department
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Designation
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="6"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Period
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ employeeBasicPayrollData.department }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ employeeBasicPayrollData.designation }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6" class="py-3">
                                                                            <h5 v-if="currentPayRun">
                                                                                {{ new Date(currentPayRun.from_date).toUTCString().substring(0, 16) + ' - ' + new Date(currentPayRun.to_date).toUTCString().substring(0, 16) }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Date
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Name
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Account Number
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Method
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ currentPayRun ? new Date(currentPayRun.payment_date).toUTCString().substring(0, 16) : null }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ employeeBasicPayrollData.bankName }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ employeeBasicPayrollData.accountNumber }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ employeeBasicPayrollData.paymentMethod }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>

                                                                    <v-row>
                                                                        <v-col v-if="basicPayrollData.isTaxCompliant" cols="12">
                                                                            <v-row>
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2; border-right: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Payments
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    class="py-3"
                                                                                    style="background: #e2e2e2; border-left: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row>
                                                                                <v-col cols="6"
                                                                                    style="border-right: 10px solid #fff;">
                                                                                    <v-row style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                Basic Salary
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ employeeBasicPayrollData.salaryAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(allowance, allowanceIndex) in employeeBasicPayrollData.allowances" :key="`allowance-${allowanceIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ allowance.name + (allowance.note ? ` (${allowance.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ allowance.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Gross Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ employeeBasicPayrollData.grossPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    style="border-left: 10px solid #fff;">
                                                                                    <v-row v-for="(deduction, deductionIndex) in employeeBasicPayrollData.employeeStatutoryDeductions" :key="`statutory-deduction-${deductionIndex}`"
                                                                                        style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ deduction.name }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ deduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Total Statutory Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ employeeBasicPayrollData.employeeStatutoryDeductionsAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="12" class="py-3 pl-0"
                                                                                            style="background: #e2e2e2; border-left: 10px solid #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Non-Statutory Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(loanDeduction, loanDeductionIndex) in employeeBasicPayrollData.loanDeductions" :key="`loan-deduction-${loanDeductionIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ loanDeduction.name + (loanDeduction.note ? ` (${loanDeduction.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ loanDeduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(deduction, deductionIndex) in employeeBasicPayrollData.deductions" :key="`deduction-${deductionIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ deduction.name + (deduction.note ? ` (${deduction.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ deduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>

                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Total Non-Statutory Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ employeeBasicPayrollData.employeeNonStatutoryDeductionsAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col class="py-3" cols="6"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Net Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ employeeBasicPayrollData.netPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col v-else cols="12">
                                                                            <v-row>
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2; border-right: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Payments
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    class="py-3"
                                                                                    style="background: #e2e2e2; border-left: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-row>
                                                                                <v-col cols="6"
                                                                                    style="border-right: 10px solid #fff;">
                                                                                    <v-row style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                Basic Salary
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ employeeBasicPayrollData.salaryAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(allowance, allowanceIndex) in employeeBasicPayrollData.allowances" :key="`allowance-${allowanceIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ allowance.name + (allowance.note ? ` (${allowance.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ allowance.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Gross Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ employeeBasicPayrollData.grossPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    style="border-left: 10px solid #fff;">
                                                                                    <v-row v-for="(loanDeduction, loanDeductionIndex) in employeeBasicPayrollData.loanDeductions" :key="`loan-deduction-${loanDeductionIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ loanDeduction.name + (loanDeduction.note ? ` (${loanDeduction.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ loanDeduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(deduction, deductionIndex) in employeeBasicPayrollData.deductions" :key="`deduction-${deductionIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ deduction.name + (deduction.note ? ` (${deduction.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ deduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>

                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Total Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ employeeBasicPayrollData.employeeNonStatutoryDeductionsAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col class="py-3" cols="6"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Net Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ employeeBasicPayrollData.netPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                    <v-row class="mt-5" :class="$vuetify.breakpoint.name == 'xs' ||
                                                        $vuetify.breakpoint.name == 'sm'
                                                        ? 'px-2'
                                                        : null
                                                        ">
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="8" offset-xl="8"
                                                            class="pt-0">
                                                            <button @click="steps -= 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                        <v-col cols="6" lg="2" xl="2" class="pt-0">
                                                            <button @click="proceedToFinalStep()" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Next
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="4">
                                        <div class="container-fluid">
                                            <v-row>
                                                <v-col cols="12" class="card my-3" style="border-radius: 10px;">
                                                    <div class="card-header px-3">
                                                        <h4>Ready to approve</h4>
                                                    </div>
                                                    <div class="card-body px-1 py-1 pt-0 pr-0">
                                                        <div class="table-responsive" :style="$vuetify.breakpoint.name == 'xs' ||
                                                            $vuetify.breakpoint.name == 'sm'
                                                            ? '/*height: 30vh;*/'
                                                            : '/*height: 30vh;*/'
                                                            "
                                                            style="border-radius: 10px; overflow-y: scroll; padding-top: 0; padding-bottom: 0;">
                                                            <table v-if="payrollBreakdownData" class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            S/N
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Employee
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Department
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Basic Salary
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Allowances
                                                                        </th>
                                                                        <th v-for="(header, headerIndex) in basicPayrollData.statutoryDeductionHeaders"
                                                                            :key="`header-${headerIndex}`" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            {{ header }}
                                                                        </th>
                                                                        <th v-if="basicPayrollData.isTaxCompliant" class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total {{ basicPayrollData.isTaxCompliant ? 'Non-Statutory ' : '' }} Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Net Pay
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr v-for="(employeePayData, employeePayDataIndex) in payrollBreakdownData.employeesPayData" :key="'employee-payment-breakdown-' + employeePayDataIndex">
                                                                        <td class="text-left">{{ employeePayDataIndex + 1 }}</td>
                                                                        <td class="text-left">
                                                                            {{ employeePayData.employeeName }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            {{ employeePayData.department }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ employeePayData.salaryAmount | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ employeePayData.allowancesAmount | number_with_commas }}
                                                                        </td>
                                                                        <td v-for="(employerStatutoryDeduction, employerStatutoryDeductionIndex) in employeePayData.employerStatutoryDeductions" :key="`employer-statutory-deduction-${employerStatutoryDeductionIndex}`" class="text-left">
                                                                            ₦{{ employerStatutoryDeduction.amount | number_with_commas }}
                                                                        </td>
                                                                        <td v-for="(employeeStatutoryDeduction, employeeStatutoryDeductionIndex) in employeePayData.employeeStatutoryDeductions" :key="`employee-statutory-deduction-${employeeStatutoryDeductionIndex}`" class="text-left">
                                                                            ₦{{ employeeStatutoryDeduction.amount | number_with_commas }}
                                                                        </td>
                                                                        <td v-if="basicPayrollData.isTaxCompliant" class="text-left">
                                                                            ₦{{ employeePayData.employeeStatutoryDeductionsAmount | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ employeePayData.employeeNonStatutoryDeductionsAmount | number_with_commas }}
                                                                        </td>
                                                                        <td class="text-left">
                                                                            ₦{{ employeePayData.netPayAmount | number_with_commas }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Basic Salary
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Allowances
                                                                        </th>
                                                                        <th v-for="(header, headerIndex) in basicPayrollData.statutoryDeductionHeaders"
                                                                            :key="`header-${headerIndex}`" class="text-left"
                                                                            style="border-top: none;" scope="col">
                                                                            {{ header }}
                                                                        </th>
                                                                        <th v-if="basicPayrollData.isTaxCompliant" class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total Statutory Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Total {{ basicPayrollData.isTaxCompliant ? 'Non-Statutory ' : '' }} Deductions
                                                                        </th>
                                                                        <th class="text-left" style="border-top: none;"
                                                                            scope="col">
                                                                            Net Pay
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th class="text-left"></th>
                                                                        <th class="text-left"></th>
                                                                        <th class="text-left">TOTAL</th>
                                                                        <th class="text-left">
                                                                            ₦{{ payrollBreakdownData.payDataTotals.salaryAmount | number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ payrollBreakdownData.payDataTotals.allowancesAmount | number_with_commas }}
                                                                        </th>
                                                                        <th v-for="(employerStatutoryDeduction, employerStatutoryDeductionIndex) in payrollBreakdownData.payDataTotals.employerStatutoryDeductions" :key="`employer-statutory-deduction-${employerStatutoryDeductionIndex}`" class="text-left">
                                                                            ₦{{ employerStatutoryDeduction.amount | number_with_commas }}
                                                                        </th>
                                                                        <th v-for="(employeeStatutoryDeduction, employeeStatutoryDeductionIndex) in payrollBreakdownData.payDataTotals.employeeStatutoryDeductions" :key="`employee-statutory-deduction-${employeeStatutoryDeductionIndex}`" class="text-left">
                                                                            ₦{{ employeeStatutoryDeduction.amount | number_with_commas }}
                                                                        </th>
                                                                        <th v-if="basicPayrollData.isTaxCompliant" class="text-left">
                                                                            ₦{{ payrollBreakdownData.payDataTotals.employeeStatutoryDeductionsAmount | number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ payrollBreakdownData.payDataTotals.employeeNonStatutoryDeductionsAmount | number_with_commas }}
                                                                        </th>
                                                                        <th class="text-left">
                                                                            ₦{{ payrollBreakdownData.payDataTotals.netPayAmount | number_with_commas }}
                                                                        </th>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-row>
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="8" offset-xl="8"
                                                            class="pt-0">
                                                            <button @click="steps -= 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                        <v-col v-if="permissionIsAssigned('payroll-approve')" cols="6"
                                                            lg="2" xl="2" class="pt-0">
                                                            <button v-if="currentPayRun
                                                                ? currentPayRun.approved == 0
                                                                : null
                                                                " @click="approvePayRun()" style="width: 100%;" type="button" class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Approve
                                                            </button>
                                                            <button v-else @click="undoPayrunApproval()" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Undo Approval
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
            <div v-else-if="payrunView == 4" class="main-content pt-0" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
                ? 'px-3'
                : null
                ">
                <div class="breadcrumb" style="margin-top: 1rem;">
                    <div class="row" :style="$vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
                        ">
                        <h1 class="col-12 my-0 row pb-0">
                            <span class="col-6">Payrun</span>
                            <span class="col-6 text-right">
                                <span v-if="currentPayRun ? currentPayRun.approved == 1 : null"
                                    class="badge badge-success">APPROVED</span>
                                <span v-else class="badge badge-light">UNAPPROVED</span>
                            </span>
                        </h1>
                        <div class="col-12" :class="$vuetify.breakpoint.name == 'xs' ||
                            $vuetify.breakpoint.name == 'sm'
                            ? 'px-0'
                            : 'px-7'
                            ">
                            <v-stepper v-model="steps2" non-linear>
                                <v-stepper-header>
                                    <v-stepper-step :editable="true" step="1">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "PaySlips"
                                            : null }}
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :editable="true" step="2">
                                        {{ $vuetify.breakpoint.name != "xs" && $vuetify.breakpoint.name != "sm" ? "Overview"
                                            : null }}
                                    </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="1">
                                        <div class="container-fluid">
                                            <v-row>
                                                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                                                    <div v-if="approvedEmployeePayrollBreakdownData" id="employeeBreakdown" class="card">
                                                        <div class="card-header px-3">
                                                            <h4>Employee Payslip Summary</h4>
                                                        </div>
                                                        <div class="card-body px-3 pt-1 pb-8">
                                                            <div class="noShowOnPrint d-print-none row">
                                                                <v-col cols="12" lg="4" md="4" class="pb-0">
                                                                    <v-select class="remove-mb"
                                                                        :items="payCalendarEmployees"
                                                                        item-text="employeeName" return-object
                                                                        placeholder="Employee" dense solo
                                                                        hint="*Select Employee" :full-width="true"
                                                                        v-model="selectedEmployeePayCalendar"></v-select>
                                                                </v-col>
                                                                <v-col cols="12" lg="8" md="8" class="pb-0 text-right">
                                                                    <button @click="printEmployeeBreakdown" type="button"
                                                                        class="btn btn-outline-secondary m-1">
                                                                        Print Payslip
                                                                    </button>
                                                                </v-col>
                                                            </div>
                                                            <v-row class="add-mt">
                                                                <v-col cols="6">
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <h3 class="text-left">
                                                                                {{ authCompanyData.company_name }}
                                                                            </h3>
                                                                            <p>
                                                                                <i>{{ authCompanyData.address }}</i>
                                                                            </p>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Name
                                                                            </h5>
                                                                            <p>{{ selectedEmployeePayCalendar.employeeName }}</p>
                                                                            <h5 style="font-weight: bold;">
                                                                                Employee Address
                                                                            </h5>
                                                                            <p>{{ selectedEmployeePayCalendar.employeeAddress }}</p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="6" class="text-center">
                                                                    <img :src="authCompanyData.logo
                                                                            ? host_url + authCompanyData.logo
                                                                            : '/img/kylian-logo-22.png'
                                                                        " alt="" class="w-50"
                                                                        style="height: inherit;" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row class="mx-1">
                                                                <v-col cols="12">
                                                                    <v-row>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Department
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Designation
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col class="py-3" cols="6"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Period
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.department }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.designation }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="6" class="py-3">
                                                                            <h5>
                                                                                {{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.period }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Date
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Name
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Bank Account Number
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3"
                                                                            style="background: #e2e2e2;">
                                                                            <h5 style="font-weight: bold;">
                                                                                Payment Method
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.paymentDate }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.bankName }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.accountNumber }}
                                                                            </h5>
                                                                        </v-col>
                                                                        <v-col cols="3" class="py-3">
                                                                            <h5>
                                                                                {{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.paymentMethod }}
                                                                            </h5>
                                                                        </v-col>
                                                                    </v-row>

                                                                    <v-row>
                                                                        <v-col v-if="approvedEmployeePayrollBreakdownData.isTaxCompliant" cols="12">
                                                                            <v-row>
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2; border-right: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Payments
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    class="py-3"
                                                                                    style="background: #e2e2e2; border-left: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Statutory Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    style="border-right: 10px solid #fff;">
                                                                                    <v-row style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                Basic Salary
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.salaryAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(allowance, allowanceIndex) in approvedEmployeePayrollBreakdownData.payslipData.employeePayslipAllowances" :key="`allowance-${allowanceIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ allowance.allowance + (allowance.note ? ` (${allowance.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ allowance.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Gross Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.grossPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    style="border-left: 10px solid #fff;">
                                                                                    <v-row 
                                                                                    v-for="(deduction, deductionIndex) in approvedEmployeeStatutoryDeductions" 
                                                                                    :key="`statutory-deduction-${deductionIndex}`" 
                                                                                    style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ deduction.deduction }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ deduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Total Statutory Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.statutoryDeductionsAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="12" class="py-3 pl-0"
                                                                                            style="background: #e2e2e2; border-left: 10px solid #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Non-Statutory Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(deduction, deductionIndex) in approvedEmployeePayrollBreakdownData.payslipData.employeePayslipDeductions" :key="`deduction-${deductionIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ deduction.deduction + (deduction.note ? ` (${deduction.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ deduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>

                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Total Non-Statutory Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.nonStatutoryDeductionsAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col class="py-3" cols="6"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Net Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.netPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col v-else cols="12">
                                                                            <v-row>
                                                                                <v-col cols="6" class="py-3"
                                                                                    style="background: #e2e2e2; border-right: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Payments
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    class="py-3"
                                                                                    style="background: #e2e2e2; border-left: 10px solid #fff;">
                                                                                    <h5 style="font-weight: bold;">
                                                                                        Deductions
                                                                                    </h5>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    style="border-right: 10px solid #fff;">
                                                                                    <v-row style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                Basic Salary
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.salaryAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row v-for="(allowance, allowanceIndex) in approvedEmployeePayrollBreakdownData.payslipData.employeePayslipAllowances" :key="`allowance-${allowanceIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ allowance.allowance + (allowance.note ? ` (${allowance.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ allowance.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Gross Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.grossPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                                <v-col cols="6"
                                                                                    style="border-left: 10px solid #fff;">
                                                                                    <v-row v-for="(deduction, deductionIndex) in approvedEmployeePayrollBreakdownData.payslipData.employeePayslipDeductions" :key="`deduction-${deductionIndex}`" style="border-top: 2px solid #fff;">
                                                                                        <v-col cols="6" class="py-3">
                                                                                            <h5>
                                                                                                {{ deduction.deduction + (deduction.note ? ` (${deduction.note})` : '') }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" class="text-right py-3">
                                                                                            <h5>
                                                                                                ₦{{ deduction.amount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>

                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col cols="6" class="py-3"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Total Deductions
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.nonStatutoryDeductionsAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                    <v-row style="border-top: 2px solid #8c8c8c;">
                                                                                        <v-col class="py-3" cols="6"
                                                                                            style="background: #e2e2e2;">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                Net Pay
                                                                                            </h5>
                                                                                        </v-col>
                                                                                        <v-col cols="6" style="background: #e2e2e2;"
                                                                                            class="text-right py-3">
                                                                                            <h5 style="font-weight: bold;">
                                                                                                ₦{{ approvedEmployeePayrollBreakdownData.payslipData.employeePayslip.netPayAmount | number_with_commas }}
                                                                                            </h5>
                                                                                        </v-col>
                                                                                    </v-row>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </div>
                                                    <v-row class="mt-5" :class="$vuetify.breakpoint.name == 'xs' ||
                                                        $vuetify.breakpoint.name == 'sm'
                                                        ? 'px-2'
                                                        : null
                                                        ">
                                                        <v-col offset="6" cols="6" lg="2" xl="2" offset-lg="10"
                                                            offset-xl="10" class="pt-0">
                                                            <button @click="fetchApprovedPayrunBreakdown(currentPayRunId)" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Next
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content :class="$vuetify.breakpoint.name == 'xs' ||
                                        $vuetify.breakpoint.name == 'sm'
                                        ? 'px-0'
                                        : null
                                        " step="2">
                                        <div class="container-fluid">
                                            <v-row v-if="approvedPayrollBreakdownData">
                                                <v-col cols="12" offset-sm="6" offset-md="6" sm="6" md="6" class="pb-0 text-right">
                                                    <button @click="printPayrunBreakdown" type="button"
                                                        class="btn btn-outline-secondary m-1">
                                                        Print Breakdown
                                                    </button>
                                                </v-col>
                                                <PayRunBreakdown 
                                                v-if="approvedPayrollBreakdownData"
                                                :authCompanyData="authCompanyData"
                                                :host_url="host_url"
                                                :approvedPayrollBreakdownData="approvedPayrollBreakdownData"
                                                :showSignature="true"
                                                />
                                                <v-col cols="12">
                                                    <v-row>
                                                        <v-col cols="6" lg="2" xl="2" offset-lg="10" offset-xl="10"
                                                            class="pt-0">
                                                            <button @click="steps2 -= 1" style="width: 100%;" type="button"
                                                                class="btn btn-secondary m-1 text-white btn-raised ripple">
                                                                Previous
                                                            </button>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- ICON BG-->
                </div>
            </div>
      </div>
      <v-app>
          <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
      </v-app>
  </div>
</template>

<script>
import { ASSETS_URL, BASE_URL } from "@/main";
import { mapState, mapGetters, mapActions } from "vuex";
import PayRunBreakdown from '../PayRunBreakdown';
import axios from "axios";
export default {
    components: {
        PayRunBreakdown,
    },
  data() {
      return {
        isEditing: false,
        isViewingPayrun: false,
          steps: 1,
          steps2: 1,
          payrunView: 1,
          initialPaymentStatusInterval: null,
          FLBalanceInterval: null,
          overlay: true,
          basicPayrollData: null,
          payrollBreakdownData: null,
          payrunForm: {
              fromDate: "",
              toDate: "",
              paymentDate: "",
          },
          currentPayRunId: null,
          currentPayCalendarId: null,
          currentPayRun: { approved: null },
          payCalendarEmployees: [],
          employeeBasicPayrollData: null,
          picker: null,
          picker2: null,
          picker3: null,
          menu: false,
          menu2: false,
          menu3: false,
          selectedEmployeePayCalendar: {employeeName: '', employeeAddress: '', id: null, employeeId: null},
          flexibleAllowanceForm: {
              allowanceName: "",
              allowanceAmount: "",
              note: "",
          },
          flexibleDeductionForm: {
              deductionName: "",
              deductionAmount: "",
              note: "",
          },
          configAllowances: [],
          configDeductions: [],
          approvedPayrollBreakdownData: null,
          approvedEmployeePayrollBreakdownData: null,
      }
  },
  computed: {
      ...mapState([
          "userToken",
      ]),
      ...mapGetters([
          "userType",
          "authPayCalendars",
          "authCompanyData",
          "authCompanyPayRun",
      ]),
      excelSheetName() {
        const _this = this;
        return _this.approvedPayrollBreakdownData
                    ? `Pay Run Breakdown: ${_this.approvedPayrollBreakdownData.payslipData.totals.fromDate} - ${_this.approvedPayrollBreakdownData.payslipData.totals.toDate}`
                    : 'Pay Run Breakdown';
      },
      approvedEmployeeStatutoryDeductions() {
        const _this = this;
        let deductions = [];
        if (_this.approvedEmployeePayrollBreakdownData) {
            deductions = _this.approvedEmployeePayrollBreakdownData.payslipData.employeePayslipStatutoryDeductions.filter(deduction => deduction.is_for_employee);
        }
        return deductions;
      },
      host_url() {
          return ASSETS_URL;
      },
      printOptions() {
        const options = {
            name: '_blank',
            specs: [
                'fullscreen=yes',
                'titlebar=yes',
                'scrollbars=yes'
            ],
            styles: [
                '/assets/print/bootstrap/4.0.0/css/bootstrap.min.css',
                '/assets/print/kidlat-css@0.0.20/css/kidlat.css',
            ],
            timeout: 1000, // default timeout before the print window appears
        }
        return options;
      },
      actualHeaders() {
                const _this = this;
                let headers = [];
                if (_this.approvedPayrollBreakdownData) {
                    if (_this.approvedPayrollBreakdownData.payslipData.employeePayslipsData.length >= 1) {
                        headers = _this.approvedPayrollBreakdownData.payslipData.employeePayslipsData[0].employeePayslipStatutoryDeductions.map(statutoryDeduction => statutoryDeduction.deduction);
                    }
                }
                return headers;
            },
        jsonData: function () {
            const _this = this;
            let isTaxCompliant = false, data = [];
            if (_this.approvedPayrollBreakdownData) {
                if (_this.approvedPayrollBreakdownData.isTaxCompliant) {
                    isTaxCompliant = true;
                }
                data = _this.approvedPayrollBreakdownData.payslipData.employeePayslipsData.map((payslipsData, payslipsDataIndex) => {
                    const employeePayslipsData = {};
                    employeePayslipsData.count = (payslipsDataIndex + 1);
                    employeePayslipsData.employeeName = payslipsData.employeePayslip.employeeName;
                    employeePayslipsData.department = payslipsData.employeePayslip.department;
                    employeePayslipsData.salaryAmount = payslipsData.employeePayslip.salaryAmount;
                    employeePayslipsData.allowancesAmount = payslipsData.employeePayslip.allowancesAmount;
                    employeePayslipsData.nonStatutoryDeductionsAmount = payslipsData.employeePayslip.nonStatutoryDeductionsAmount;
                    if (isTaxCompliant) {
                        employeePayslipsData.statutoryDeductionsAmount = payslipsData.employeePayslip.statutoryDeductionsAmount;
                    }
                    payslipsData.employeePayslipStatutoryDeductions.forEach((payslipStatutoryDeduction, payslipStatutoryDeductionIndex) => {
                        employeePayslipsData[`statutoryDeduction${payslipStatutoryDeductionIndex}`] = payslipStatutoryDeduction.amount;
                    });
                    employeePayslipsData.grossPayAmount = payslipsData.employeePayslip.grossPayAmount;
                    employeePayslipsData.netPayAmount = payslipsData.employeePayslip.netPayAmount;
                    employeePayslipsData.bankName = payslipsData.employeePayslip.bankName;
                    employeePayslipsData.accountNumber = payslipsData.employeePayslip.accountNumber;
                    employeePayslipsData.bankCode = payslipsData.employeePayslip.bankCode;
                    return employeePayslipsData;
                });
            }

            return data;
        },
        jsonFields: function () {
            const _this = this;
            let isTaxCompliant = false;
            const statutoryHeaders = {};
            if (_this.approvedPayrollBreakdownData) {
                if (_this.approvedPayrollBreakdownData.isTaxCompliant) {
                    isTaxCompliant = true;
                }
                _this.actualHeaders.forEach((statutoryDeductionHeader, statutoryDeductionHeaderIndex) => {
                    statutoryHeaders[`${statutoryDeductionHeader} (₦)`] = `statutoryDeduction${statutoryDeductionHeaderIndex}`;
                });
            }
            return {
                "S/N": "count",
                "Employee": "employeeName",
                "Department": "department",
                "Basic Salary (₦)": "salaryAmount",
                "Total Allowances (₦)": "allowancesAmount",
                ...(
                    isTaxCompliant
                    ? {
                        "Total Statutory Deductions (₦)": "statutoryDeductionsAmount",
                        "Total Non-Statutory Deductions (₦)": "nonStatutoryDeductionsAmount",
                    }
                    : {
                        "Total Deductions (₦)": "nonStatutoryDeductionsAmount",
                    }
                ),
                ...statutoryHeaders,
                "Gross Pay (₦)": "grossPayAmount",
                "Net Pay (₦)": "netPayAmount",
                "Bank": "bankName",
                "Account Number": "accountNumber",
                "Bank Code": "bankCode",
            }
        },
  },
  watch: {
      menu(val) {
          val && setTimeout(() => (this.picker = "YEAR"));
      },
      menu2(val) {
          val && setTimeout(() => (this.picker2 = "YEAR"));
      },
      menu3(val) {
          val && setTimeout(() => (this.picker3 = "YEAR"));
      },
      selectedEmployeePayCalendar() {
        const _this = this;
        if (_this.isViewingPayrun) {
            _this.fetchEmployeeApprovedPayrunBreakdown(_this.currentPayRunId);
        } else {
            _this.updateEmployeePayrunBasicData(_this.selectedEmployeePayCalendar.id);
        }
      }
  },
  methods: {
      ...mapActions([
          "fetch_all_pay_calendar",
          "fetch_all_company_payrun",
      ]),
      hasHistory: function () {
          return window.history.length > 2;
      },
      goToFirstStep() {
        const _this = this;
        _this.steps -= 1;
        _this.fetchPayRunBasicInfo(_this.currentPayCalendarId, _this.currentPayRunId);
      },
      viewAllPayruns() {
        this.fetchPayRuns();
          this.payrunView = 2;
          this.steps = 1;
          clearInterval(this.initialPaymentStatusInterval);
      },
      viewDefault() {
          const _this = this;
          _this.payrunView = 1;
          _this.steps = 1;
          _this.payrunForm.fromDate = "";
          _this.payrunForm.toDate = "";
          _this.payrunForm.paymentDate = "";
          _this.currentPayRunId = null;
          _this.currentPayCalendarId = null;
          clearInterval(_this.initialPaymentStatusInterval);
      },
      viewCompanyTrasactions() {
          this.payrunView = 6;
          this.steps = 1;
          clearInterval(this.initialPaymentStatusInterval);
      },
      startInitialPaymentStatusInterval() {
          const _this = this;
          _this.initialPaymentStatusInterval = setInterval(() => {
            //   _this.requeryTransaction();
          }, 30000);
      },
      startPayrun(payCalendarId) {
          const _this = this;
          _this.payrunForm.fromDate = "";
          _this.payrunForm.toDate = "";
          _this.payrunForm.paymentDate = "";
          _this.currentPayRunId = null;
          _this.currentPayCalendarId = payCalendarId;
          _this.overlay = true;
          _this.steps = 1;
          _this.isEditing = false;
          _this.isViewingPayrun = false;
          _this.fetchPayRunBasicInfo(payCalendarId);
      },
      fetchPayRunBasicInfo(payCalendarId, payRunId = null) {
        const _this = this;
        _this.overlay = true;
        axios
          .get(`${BASE_URL}payroll/basic-info/${payCalendarId}${payRunId ? ('/' + payRunId) : ''}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.basicPayrollData = response.data;
              _this.payrunView = 3;
          })
          .catch(function () {
              //
          })
          .then(function () {
              _this.overlay = false;
              _this.fetchConfigAllowances();
              _this.fetchConfigDeductions();
          });
      },
      editPayRun(p) {
            let _this = this;
            _this.steps = 1;
            _this.payrunForm.fromDate = p.from_date;
            _this.payrunForm.toDate = p.to_date;
            _this.payrunForm.paymentDate = p.payment_date;
            _this.currentPayRunId = p.id;
            _this.currentPayCalendarId = p.paycalendar_id;
            _this.isEditing = true;
            _this.isViewingPayrun = false;
            _this.removeDispensableItems(p.id, p.paycalendar_id)
        },
        async viewPayrunInfo(payrun) {
            const _this = this;
            _this.isViewingPayrun = true;
            _this.currentPayRunId = payrun.id;
            _this.steps2 = 1;
            await _this.fetchPayCalendarEmployees(payrun.paycalendar_id, payrun.id);
        },
      async fetchPayrunBreakdown() {
          const _this = this;
          _this.overlay = true;

          await axios
          .get(`${BASE_URL}payroll/breakdown/${_this.currentPayCalendarId}/${_this.currentPayRunId}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.payrollBreakdownData = response.data;
              _this.steps += 1;
          })
          .catch(function () {
              //
          })
          .then(function () {
              _this.overlay = false;
          });
      },
      async fetchApprovedPayrunBreakdown(payRunId) {
          const _this = this;
          _this.overlay = true;
          let isSuccessful = false;

          await axios
          .get(`${BASE_URL}payroll/payruns/${payRunId}/approved`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.approvedPayrollBreakdownData = response.data;
              isSuccessful = true;
              _this.steps2 += 1;
          })
          .catch(function () {
              //
          })
          .then(function () {
              _this.overlay = false;
          });
          return isSuccessful;
      },
      async fetchEmployeeApprovedPayrunBreakdown(payRunId) {
          const _this = this;
          _this.overlay = true;

          await axios
          .get(`${BASE_URL}payroll/payruns/${payRunId}/approved/${_this.selectedEmployeePayCalendar.employeeId}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.approvedEmployeePayrollBreakdownData = response.data;
              _this.payrunView = 4
          })
          .catch(function () {
              //
          })
          .then(function () {
              _this.overlay = false;
          });
      },
      updatePayrunBasicData(route) {
          if (!route) {
            return;
          }
          const _this = this;
          _this.overlay = true;

          axios
          .get(`${route}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.basicPayrollData = response.data;
          })
          .catch(function () {
              //
          })
          .then(function () {
              _this.overlay = false;
          });
      },
      async fetchPayCalendarEmployees(payCalendarId, payRunId = null) {
        const _this = this;
        _this.overlay = true;

        await axios
          .get(`${BASE_URL}payroll/pay-calendar-employees/${payCalendarId}${payRunId ? ('/' + payRunId) : ''}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.payCalendarEmployees = response.data.payCalendarEmployees;
              if (response.data.payCalendarEmployees.length >= 1) {
                _this.selectedEmployeePayCalendar = response.data.payCalendarEmployees[0];
              }
          })
          .catch(function () {
              //
          })
          .then(function () {
            _this.overlay = false;
          });
      },
      async fetchPayrun(payrunId) {
          const _this = this;
          _this.overlay = true;

          await axios
          .get(`${BASE_URL}payroll/payruns/${payrunId}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.currentPayRun = response.data.data;
          })
          .catch(function () {
            _this.overlay = false;
          })
          .then(function () {
            //
          });
      },
      async removeDispensableItems(payrunId, payCalendarId) {
          const _this = this;
          _this.overlay = true;

          await axios
          .delete(`${BASE_URL}payroll/dispensable-items/${payrunId}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function () {
            //
          })
          .catch(function () {
            //
          })
          .then(function () {
            _this.overlay = false;
            _this.fetchPayRunBasicInfo(payCalendarId, payrunId);
          });
      },
      async addPayrun() {
          let _this = this;
          const valid = await _this.$refs.form1.validate();
          if (!valid) {
              return;
          }
          const Toast = _this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
              },
          });
          _this.$swal
                  .fire({
                      title: `Are you sure you want to ${_this.isEditing ? 'update' : 'create'} this payrun?`,
                      showCancelButton: true,
                      confirmButtonText: `Yes, ${_this.isEditing ? 'Update' : 'Create'}`,
                  })
                  .then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        _this.overlay = true;

                          axios
                              .post(`${BASE_URL}${_this.isEditing ? 'updatepayrun' : 'createpayrun'}`, {
                                  company_id: _this.authCompanyData.id,
                                  paycalendar_id: _this.currentPayCalendarId,
                                  from_date: _this.payrunForm.fromDate,
                                  to_date: _this.payrunForm.toDate,
                                  payment_date: _this.payrunForm.paymentDate,
                                  ...(_this.isEditing ? {payrun_id: _this.currentPayRunId} : {})
                              })
                              .then(function (response) {
                                  _this.currentPayRunId = response.data.payruns.id;
                                  _this.fetchPayrun(response.data.payruns.id).then(() => {
                                    _this.fetchPayCalendarEmployees(_this.currentPayCalendarId);
                                  });
                                  // _this.employee_name = _this.allEmployees[0];

                                  // if (_this.subscribedToAttendance && _this.authCompanyAttendanceDeductionSettings) {
                                  //     _this.loadAttendanceRecords(_this.currentPayCalendarId, response.data.payruns.id);
                                  // } else {
                                      Toast.fire({
                                          icon: "success",
                                          title: `Payrun was ${_this.isEditing ? 'updated' : 'created'} successfully.`,
                                      });
                                  // }
                              })
                              .catch(function (error) {
                                  let errors = error.response.data.error || null;
                                  Toast.fire({
                                      icon: "error",
                                      title: errors
                                          ? typeof errors === "string"
                                              ? errors
                                              : null || errors.from_date
                                                  ? errors.from_date[0]
                                                  : null || errors.to_date
                                                      ? errors.to_date[0]
                                                      : null || errors.payment_date
                                                          ? errors.payment_date[0]
                                                          : null
                                          : null || error.response.data.error || error.response.data.message || `Payrun could not be ${_this.isEditing ? 'updated' : 'created'}`,
                                  });
                                  _this.overlay = false;
                              })
                              .then(function () {
                                //
                              });
                      }
                  });
      },
      updateEmployeePayrunBasicData(payCalendarEmployeeId) {
          const _this = this;
          _this.overlay = true;

          axios
          .get(`${BASE_URL}payroll/employee-basic-info/${payCalendarEmployeeId}${_this.currentPayRunId ? ('/'+_this.currentPayRunId) : ''}`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.employeeBasicPayrollData = response.data.employeePayData;
              _this.overlay = false;
              if (_this.steps == 1) {
                _this.steps = 2;
              }
          })
          .catch(function () {
              //
          })
          .then(function () {
            //
          });
      },
      fetchConfigAllowances() {
          const _this = this;
          _this.overlay = true;

          axios
          .get(`${BASE_URL}payroll/config-allowances`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.configAllowances = response.data.configAllowances;
              _this.overlay = false;
          })
          .catch(function () {
              //
          })
          .then(function () {
            //
          });
      },
      fetchConfigDeductions() {
          const _this = this;
          _this.overlay = true;

          axios
          .get(`${BASE_URL}payroll/config-deductions`, {
              headers: {
                  Authorization: `Bearer ${_this.userToken}`
              }
          })
          .then(function (response) {
              _this.configDeductions = response.data.configDeductions;
              _this.overlay = false;
          })
          .catch(function () {
              //
          })
          .then(function () {
            //
          });
      },
      addFlexibleAllowance() {
          let _this = this;
          _this.overlay = true;
          const Toast = _this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
              },
          });
          axios
              .post(`${BASE_URL}createemployeeallowance`, {
                  company_id: _this.authCompanyData.id,
                  paycalendar_id: _this.currentPayCalendarId,
                  payrun_id: _this.currentPayRunId,
                  employee_id: _this.selectedEmployeePayCalendar.employeeId,
                  allowance_name: _this.flexibleAllowanceForm.allowanceName,
                  allowance_amount: _this.flexibleAllowanceForm.allowanceAmount,
                  note: _this.flexibleAllowanceForm.note,
              })
              .then(function () {
                  Toast.fire({
                      icon: "success",
                      title: "Allowance added successfully",
                  });
                  _this.overlay = false;
                  _this.updateEmployeePayrunBasicData(_this.selectedEmployeePayCalendar.id);
                  _this.flexibleAllowanceForm.allowanceName = "";
                  _this.flexibleAllowanceForm.allowanceAmount = "";
                  _this.flexibleAllowanceForm.note = "";
              })
              .catch(function (error) {
                  let errors = error.response.data.error || null;
                  Toast.fire({
                      icon: "error",
                      title: errors
                          ? typeof errors === "string"
                              ? errors
                              : null || errors.allowanceName
                                  ? errors.allowanceName[0]
                                  : null || errors.allowanceAmount
                                      ? errors.allowanceAmount[0]
                                      : null
                          : null || error.response.data.error || error.response.data.message || "Allowance couldn't be added",
                  });
                  _this.overlay = false;
              })
              .then(function () {
                //
              });
      },
      addFlexibleDeduction() {
          let _this = this;
          _this.overlay = true;
          const Toast = _this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
              },
          });
          axios
              .post(`${BASE_URL}createemployeededuction`, {
                  company_id: _this.authCompanyData.id,
                  paycalendar_id: _this.currentPayCalendarId,
                  payrun_id: _this.currentPayRunId,
                  employee_id: _this.selectedEmployeePayCalendar.employeeId,
                  deduction_name: _this.flexibleDeductionForm.deductionName,
                  deduction_amount: _this.flexibleDeductionForm.deductionAmount,
                  note: _this.flexibleDeductionForm.note,
              })
              .then(function () {
                  Toast.fire({
                      icon: "success",
                      title: "Deduction added successfully",
                  });
                  _this.overlay = false;
                  _this.updateEmployeePayrunBasicData(_this.selectedEmployeePayCalendar.id);
                  _this.flexibleDeductionForm.deductionName = "";
                  _this.flexibleDeductionForm.deductionAmount = "";
                  _this.flexibleDeductionForm.note = "";
              })
              .catch(function (error) {
                  let errors = error.response.data.error || null;
                  Toast.fire({
                      icon: "error",
                      title: errors
                          ? typeof errors === "string"
                              ? errors
                              : null || errors.deductionName
                                  ? errors.deductionName[0]
                                  : null || errors.deductionAmount
                                      ? errors.deductionAmount[0]
                                      : null
                          : null || error.response.data.error || error.response.data.message || "Deduction couldn't be added",
                  });
                  _this.overlay = false;
              })
              .then(function () {
                //
              });
      },
      deleteFlexibleAllowance(allowance) {
          const _this = this;
          _this.$swal
              .fire({
                  title: "Are you sure you want to remove this allowance?",
                  showCancelButton: true,
                  confirmButtonText: `Yes, Remove`,
              })
              .then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                      const Toast = _this.$swal.mixin({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 2000,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                              toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                              toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                          },
                      });
                      if (allowance.type == 'fixed') {
                        // Add to skip fixed allowance
                        _this.addDispensablePayItem({
                          employee_id: _this.selectedEmployeePayCalendar.employeeId,
                          payrun_id: _this.currentPayRunId,
                          reference_id: false,
                          type: allowance.type,
                          isAllowance: true,
                        });
                      } else {
                        _this.overlay = true;
                        axios
                          .post(`${BASE_URL}removeemployeeallowance`, {
                              allowance_id: allowance.employeeAllowanceId,
                          })
                          .then(function () {
                              Toast.fire({
                                  icon: "success",
                                  title: "Allowance removed successfully",
                              });
                              _this.overlay = false;
                              _this.updateEmployeePayrunBasicData(_this.selectedEmployeePayCalendar.id);
                          })
                          .catch(function (error) {
                              let errors = error.response.data.error || null;
                              Toast.fire({
                                  icon: "error",
                                  title: errors
                                      ? typeof errors === "string"
                                          ? errors
                                          : null || errors.allowance_id
                                              ? errors.allowance_id[0]
                                              : null
                                      : null || error.response.data.error || error.response.data.message || "Allowance couldn't be removed",
                              });
                          })
                          .then(function () {
                              //
                          });
                      }
                  }
              });
      },
      deleteFlexibleDeduction(deduction) {
          const _this = this;
          _this.$swal
              .fire({
                  title: "Are you sure you want to remove this deduction?",
                  showCancelButton: true,
                  confirmButtonText: `Yes, Remove`,
              })
              .then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                      const Toast = _this.$swal.mixin({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 2000,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                              toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                              toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                          },
                      });
                      if (deduction.type == 'fixed') {
                        // Add to skip fixed deduction
                        _this.addDispensablePayItem({
                          employee_id: _this.selectedEmployeePayCalendar.employeeId,
                          payrun_id: _this.currentPayRunId,
                          reference_id: null,
                          type: deduction.type,
                          isAllowance: false,
                        });
                      } else if (deduction.type == 'loan') {
                        // Add to skip loan deduction
                        _this.addDispensablePayItem({
                          employee_id: _this.selectedEmployeePayCalendar.employeeId,
                          payrun_id: _this.currentPayRunId,
                          reference_id: deduction.loanId,
                          type: deduction.type,
                          isAllowance: false,
                        });
                      } else {
                        _this.overlay = true;
                        axios
                          .post(`${BASE_URL}removeemployeededuction`, {
                              deduction_id: deduction.employeeDeductionId,
                          })
                          .then(function () {
                              Toast.fire({
                                  icon: "success",
                                  title: "Deduction removed successfully",
                              });
                              _this.overlay = false;
                              _this.updateEmployeePayrunBasicData(_this.selectedEmployeePayCalendar.id);
                          })
                          .catch(function (error) {
                              let errors = error.response.data.error || null;
                              Toast.fire({
                                  icon: "error",
                                  title: errors
                                      ? typeof errors === "string"
                                          ? errors
                                          : null || errors.deduction_id
                                              ? errors.deduction_id[0]
                                              : null
                                      : null || error.response.data.error || error.response.data.message || "Deduction couldn't be removed",
                              });
                          })
                          .then(function () {
                              //
                          });
                      }
                  }
              });
      },
      addDispensablePayItem(payItem) {
          const _this = this;
          _this.overlay = true;
          const Toast = _this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                  toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
              },
          });

          axios
            .post(`${BASE_URL}payroll/dispensable-items`, {
              employee_id: payItem.employee_id,
              payrun_id: payItem.payrun_id,
              type: payItem.type,
              ...(payItem.reference_id ? { reference_id: payItem.reference_id } : {}),
            }, {
                headers: {
                    Authorization: `Bearer ${_this.userToken}`
                }
            })
            .then(function () {
                Toast.fire({
                    icon: "success",
                    title: `${payItem.isAllowance ? 'Allowance' : 'Deduction'} removed successfully`,
                });
                _this.overlay = false;
                _this.updateEmployeePayrunBasicData(_this.selectedEmployeePayCalendar.id);
            })
            .catch(function (error) {
                let errors = error.response.data.error || null;
                Toast.fire({
                    icon: "error",
                    title: errors ? (typeof errors === 'string' ? errors : null
                          || errors.employee_id ? errors.employee_id[0] : null
                            || errors.payrun_id ? errors.payrun_id[0] : null
                              || errors.reference_id ? errors.reference_id[0] : null
                                || errors.type ? errors.type[0] : null) : null
                                || error.response.data.error || error.response.data.message || `${payItem.isAllowance ? 'Allowance' : 'Deduction'} couldn't be removed`
                });
            })
            .then(function () {
                //
            });
      },
        async exportToExcel(payrun) {
            const _this = this;
            await _this.fetchApprovedPayrunBreakdown(payrun.id);
            setTimeout(() => {
                document.getElementById("export-button").click();
            }, 1000);
        },
        async triggerPrintPayRunBreakdown(payrun) {
            const _this = this;
            await _this.fetchApprovedPayrunBreakdown(payrun.id);
            setTimeout(() => {
                _this.printPayrunBreakdown();
            }, 1000);
        },
        deletePayRun(payRun) {
            let _this = this;
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.$swal
                .fire({
                    title: "Are you sure you want to delete this payrun?",
                    showCancelButton: true,
                    confirmButtonText: `Yes, Delete`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        _this.overlay = true;
                        axios
                            .post(`${BASE_URL}deletepayrun`, {
                                payrun_id: payRun.id,
                            })
                            .then(function () {
                                Toast.fire({
                                    icon: "success",
                                    title: "Payrun was deleted successfully.",
                                });
                                _this.fetchPayRuns();
                            })
                            .catch(function (error) {
                                let errors = error.response.data.error || null;
                                Toast.fire({
                                    icon: "error",
                                    title: errors
                                        ? typeof errors === "string"
                                            ? errors
                                            : null || errors.payrun_id
                                                ? errors.payrun_id[0]
                                                : null
                                        : null || error.response.data.error || error.response.data.message || "Payrun could not be deleted",
                                });
                                _this.overlay = false;
                            })
                            .then(function () {
                                //
                            });
                    }
                });
        },
      proceedToFinalStep() {
        const _this = this;
        _this.fetchPayrunBreakdown();
      },
      async fetchPayRuns() {
        this.overlay = true;
        await this.fetch_all_company_payrun();
        this.overlay = false;
      },
      approvePayRun() {
        const _this = this;

        _this.$swal
            .fire({
                title: "Are you sure you want to approve this pay run?",
                showCancelButton: true,
                confirmButtonText: `Yes, Approve`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    _this.overlay = true;
                    const Toast = _this.$swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                            toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                        },
                    });

                    axios
                        .post(`${BASE_URL}payroll/payruns/${_this.currentPayRunId}/approve`, {}, {
                            headers: {
                                Authorization: `Bearer ${_this.userToken}`
                            }
                        })
                        .then(function () {
                            Toast.fire({
                                icon: "success",
                                title: 'Pay run approved successfully',
                            });
                            _this.viewAllPayruns();
                        })
                        .catch(function (error) {
                            let errors = (error.response ? error.response.data.error : null) || null;
                            Toast.fire({
                                icon: "error",
                                title: errors ? (typeof errors === "string" ? errors : null || errors.payrun_id ? errors.payrun_id[0] : null) : null || error.response.data.error || error.response.data.message || "Pay run could not be approved",
                            });
                        })
                        .then(function () {
                            _this.overlay = false
                        });
                }
            });
      },
    undoPayrunApproval() {
        const _this = this;

        const Toast = _this.$swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
            },
        });
        _this.$swal
            .fire({
                title: "Are you sure you want to undo approval?",
                showCancelButton: true,
                confirmButtonText: `Yes, Undo`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    _this.overlay = true;

                    axios
                        .post(`${BASE_URL}disapprovepayrun`, {
                            company_id: _this.authCompanyData.id,
                            payrun_id: _this.currentPayRunId,
                        })
                        .then(function () {
                            Toast.fire({
                                icon: "success",
                                title: "Payrun disapproved successfully.",
                            });
                            _this.fetchPayRuns();
                            _this.payrunView = 2;
                        })
                        .catch(function (error) {
                            let errors = (error.response ? error.response.data.error : null) || null;
                            Toast.fire({
                                icon: "error",
                                title: errors
                                    ? typeof errors === "string"
                                        ? errors
                                        : null || errors.payrun_id
                                            ? errors.payrun_id[0]
                                            : null
                                    : null || error.response.data.error || error.response.data.message || "Payrun could not be approved",
                            });
                        })
                        .then(function () {
                            _this.overlay = false;
                        });
                }
            });
    },
      async printEmployeeBreakdown() {
        const _this = this;
        await _this.$htmlToPaper('employeeBreakdown', {
            ..._this.printOptions,
            windowTitle: (
                _this.selectedEmployeePayCalendar && _this.currentPayRun
                ? `Payslip for ${_this.selectedEmployeePayCalendar.employeeName} - ${_this.currentPayRun.payrun_name}`
                : window.document.title
            ), // override the window title
        });
        },
        async printPayrunBreakdown() {
            const _this = this;
            await _this.$htmlToPaper('payrunBreakdown', {
                ..._this.printOptions,
                windowTitle: (
                    _this.approvedPayrollBreakdownData
                    ? `Pay run breakdown: ${_this.approvedPayrollBreakdownData.payslipData.totals.fromDate} - ${_this.approvedPayrollBreakdownData.payslipData.totals.toDate}`
                    : window.document.title
                ), // override the window title
            });
        },
      save: function (t) {
          this.$refs.menu.save(t);
      },
      save2: function (t) {
          this.$refs.menu2.save(t);
      },
      save3: function (t) {
          this.$refs.menu3.save(t);
      },
  },
  beforeDestroy() {
      const _this = this;
      clearInterval(_this.FLBalanceInterval);
      clearInterval(_this.initialPaymentStatusInterval);
  },
  mounted() {
      const _this = this;
      _this.fetch_all_pay_calendar().then(() => {
        _this.overlay = false;
      });
  }
}
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0px !important;
}
</style>